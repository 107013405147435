<template>
  <div>
    <v-hover
      v-slot:default="{ hover }"
    >
    <v-card outlined tile :class="{ 'on-hover': hover }">
      <v-list-item>
        <v-list-item-action>
          <v-checkbox
            v-model="selected"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-action>
          <v-avatar size="76" v-if="student.photoURL">
            <img
              :src="student.photoURL"
            >
          </v-avatar>
          <v-avatar size="76" v-else>
            <v-icon size="76" color="grey lighten-3">mdi-account-circle</v-icon>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content class="ml-10 my-1 my-md-5">
          <v-row>
            <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
              <v-list-item-title>
                <div class="text-h6 mb-2">
                  {{ student.name }}
                </div>
                <div class="text-subtitle-2" style="padding-left:2px">
                  {{ student.grade }}
                </div>
                <!-- <div class="text-caption">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                        <v-icon
                            dense
                            color="red darken-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >mdi-stamper</v-icon>
                          {{ student.stickerCnt }}
                        </template>
                      <span>스티커</span>
                    </v-tooltip>
                    /
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        {{ numberWithComma }}
                        <v-icon
                            dense
                            color="teal darken-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >mdi-file-powerpoint-box</v-icon>
                      </template>
                      <span>포인트</span>
                    </v-tooltip>
                </div> -->
              </v-list-item-title>
            </v-col>
            <v-col cols="12" sm="6" lg="8" class="py-0 d-flex align-center">
              <v-row>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <v-list-item-subtitle>
                  <div class="text-subtitle-1 font-weight-bold">{{ student.userid }}</div>
                  <div class="text-caption">{{ displayTime }}</div>
                  </v-list-item-subtitle>
                </v-col>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <!-- <v-btn
                    outlined tile
                    v-bind="btnSize"
                    class="mr-2"
                    color="primary"
                    @click="goSticker"
                  ><v-icon>mdi-stamper</v-icon></v-btn> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item-content>
        <v-card-actions class="d-none d-sm-flex">
          <v-btn
            outlined tile
            v-bind="btnSize"
            color="primary"
            @click="goEdit"
          ><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn
            outlined tile
            v-bind="btnSize"
            color="error"
            @click="goDel"
          ><v-icon>mdi-delete</v-icon></v-btn>
        </v-card-actions>
      </v-list-item>
    </v-card>
    </v-hover>
  </div>
</template>
<script>
export default {
  props: ['student', 'allSelected', 'clear'],
  data () {
    return {
      selected: false
    }
  },
  watch: {
    selected: function () {
      // console.log('watch selected', this.selected)
      if (this.selected) {
        this.set()
      } else {
        this.reset()
      }
    },
    allSelected: function () {
      this.selected = this.allSelected
    },
    clear (str) {
      // console.log('clear : ', str)
      this.selected = false
    }
  },
  computed: {
    btnSize () {
      const size = { xs: 'small', sm: 'small', md: 'small' }[this.$vuetify.breakpoint.name]
      return size ? { [size]: true } : {}
    },
    displayTime () {
      const bt = this.$moment(this.student.createdAt).format('YYYY-MM-DD')
      return bt
    },
    numberWithComma () {
      const x = this.student.pointScore
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  methods: {
    goSticker () {
      this.$router.push({ name: 'point', params: { student: this.student } })
    },
    goEdit () {
      this.$emit('editItem', this.student)
    },
    goDel () {
      this.$emit('delItem', this.student)
    },
    set () {
      // console.log(this.mediaItem.id + ' selected')
      this.$emit('setItem', this.student.id)
    },
    reset () {
      // console.log(this.mediaItem.id + ' un-selected')
      this.$emit('resetItem', this.student.id)
    }
  }
}
</script>
<style scoped>

.on-hover {
  border: 1px solid #4187F9;
}
</style>
