<template>
  <div>
    <!-- <page-header :tabsItems="tabsItems" :title="title"></page-header> -->
    <v-container fluid v-if="!loaded">
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile class="mb-3 py-2">
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          </v-card>
          <v-card outlined tile class="mb-3 py-3" v-for="i in 2" :key="i">
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-else>
      <v-row justify="center" class="mb-10">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile class="mb-3">
            <v-list-item>
              <v-list-item-action>
                <v-checkbox
                  v-model="allSelected"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="selectedItems.length > 0">
                  <div>
                  선택 ({{ selectedItems.length }})
                  <span>
                    <v-btn
                      color="error"
                      text
                      @click="delItems"
                    >삭제</v-btn>
                  </span>
                  </div>
                </v-list-item-title>
                <v-list-item-title v-else>
                  총 {{ studentCnt }}명
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn class="d-none d-md-flex" color="light-green" tile dark @click="addItem"><v-icon>mdi-plus</v-icon> 신규등록</v-btn>
                <v-btn class="d-flex d-md-none" color="light-green" tile dark small @click="addItem"><v-icon>mdi-plus</v-icon></v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-card>

          <div v-if="students.length > 0">
            <student-card
              class="pb-3"
              v-for="(item, index) in students" :key="index"
              :student="item"
              :allSelected="allSelected"
              :clear="clearItemCheck"
              @setItem="setItem"
              @resetItem="resetItem"
              @editItem="editItem"
              @delItem="delItem"
            ></student-card>
          </div>
          <v-card outlined tile class="text-center" v-else>
            <v-sheet class="py-15 text-h6">등록된 학생이 없습니다</v-sheet>
          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import PageHeader from '../PageHeader'
import StudentCard from '@/components/widgets/StudentRowCard'

export default {
  components: { StudentCard },
  data () {
    return {
      title: '학원관리',
      tabsItems: [
        { id: 1, title: '학원정보', link: '/manage/academy' },
        { id: 2, title: '학생관리', link: '/manage/students' },
        { id: 3, title: '포인트', link: '/manage/point' },
        { id: 4, title: '즐겨찾기', link: '/manage/links' }
      ],
      students: [],
      allSelected: false,
      clearItemCheck: '',
      studentCnt: 0,
      selectedItems: [],
      loaded: false
    }
  },
  created () {
    this.read()
  },
  methods: {
    read () {
      let academyid = ''
      this.loaded = false
      try {
        academyid = this.$store.state.user.uid
      } catch (e) {
        academyid = localStorage.acid
      }

      this.$firebase.firestore().collection('students')
        .where('academy', '==', academyid)
        .orderBy('name')
        .onSnapshot((sn) => {
          this.loaded = true
          if (sn.empty) {
            this.students = []
          }
          this.studentCnt = sn.size

          this.students = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              name: item.name,
              grade: item.grade,
              photoURL: item.photoURL,
              photoName: item.photoName,
              stickerIcon: item.stickerIcon,
              stickerCnt: item.stickerCnt,
              pointScore: item.pointScore,
              academy: item.academy,
              status: item.status,
              createdAt: item.createdAt.toDate(),
              userid: item.userid,
              password: item.password
            }
          })
          // console.log(JSON.stringify(this.students))
        })
    },
    addItem () {
      this.$router.push('/manage/student/add')
    },
    setItem (id) {
      this.selectedItems = this.selectedItems.filter(function (n) {
        return n !== id
      })
      this.selectedItems.push(id)
    },
    resetItem (id) {
      this.selectedItems = this.selectedItems.filter(function (n) {
        return n !== id
      })
    },
    editItem (selItem) {
      // console.log('editItem : ', selItem)
      this.$router.push({ name: 'studentAdd', params: { selItem: selItem, mode: 'EDIT' } })
    },
    delItem (selItem) {
      try {
        const msg = '"' + selItem.name + '" 학생을 삭제하시겠습니까?'

        this.$swal.fire({
          title: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: '취소',
          confirmButtonText: '네, 삭제합니다',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.$firebase.firestore().collection('students').doc(selItem.id).delete()

            this.clearItemCheck = new Date().getTime()
            if (this.selectedItems.length > 0) this.allSelected = false
          }
        })
      } catch (e) {
        console.log(e.message)
      }
    },
    delItems () {
      try {
        const msg = this.selectedItems.length + ' 명 학생을 삭제하시겠습니까?'

        this.$swal.fire({
          title: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: '취소',
          confirmButtonText: '네, 삭제합니다',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.selectedItems.length > 0) {
              this.selectedItems.forEach((n) => {
                this.$firebase.firestore().collection('students').doc(n).delete()
              })
            }
            this.clearItemCheck = new Date().getTime()

            this.selectedItems = []
            if (this.selectedItems.length > 0) this.allSelected = false
          }
        })
      } catch (e) {
        console.log(e.message)
      }
    }
  }
}
</script>
<style>
